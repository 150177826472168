import React, { useState } from "react";
import {
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

function TextCounter() {
  const [text, setText] = useState("");
  const [ignoreUrls, setIgnoreUrls] = useState(true);
  const [ignorePunctuation, setIgnorePunctuation] = useState(true);
  const [ignoreNewLines, setIgnoreNewLines] = useState(false);
  const [ignoreSpaces, setIgnoreSpaces] = useState(false);
  const [customIgnoreChars, setCustomIgnoreChars] = useState("");

  const handleChangeText = (event) => {
    setText(event.target.value);
  };

  const countCharacters = () => {
    let processedText = text;
    if (ignoreUrls)
      processedText = processedText.replace(/https?:\/\/\S+/g, "");
    if (ignorePunctuation)
      processedText = processedText.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "");
    if (ignoreNewLines) processedText = processedText.replace(/\n+/g, "");
    if (ignoreSpaces) processedText = processedText.replace(/\s+/g, "");
    if (customIgnoreChars) {
      const charsToIgnore = new RegExp("[" + customIgnoreChars + "]", "g");
      processedText = processedText.replace(charsToIgnore, "");
    }
    return processedText.length;
  };

  return (
    <Box sx={{ p: 3 }}>
      <TextField
        label="ここにテキストを入力..."
        multiline
        rows={10}
        fullWidth
        value={text}
        onChange={handleChangeText}
        variant="outlined"
        margin="normal"
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={ignoreUrls}
              onChange={() => setIgnoreUrls(!ignoreUrls)}
            />
          }
          label="URLを無視する"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={ignorePunctuation}
              onChange={() => setIgnorePunctuation(!ignorePunctuation)}
            />
          }
          label="句読点や特殊記号を無視する"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={ignoreNewLines}
              onChange={() => setIgnoreNewLines(!ignoreNewLines)}
            />
          }
          label="改行を除外"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={ignoreSpaces}
              onChange={() => setIgnoreSpaces(!ignoreSpaces)}
            />
          }
          label="空白を除外"
        />
        <TextField
          label="無視する文字"
          variant="outlined"
          value={customIgnoreChars}
          onChange={(e) => setCustomIgnoreChars(e.target.value)}
          margin="normal"
          helperText="例: ABC123"
        />
      </FormGroup>
      <Box mt={2}>文字数: {countCharacters()}</Box>
    </Box>
  );
}

export default TextCounter;
